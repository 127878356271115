import React, { Fragment, useState, useEffect } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useStateContext } from "../../context/ContextProvider";
import rawMaterialService from "../../services/raw-material.service";
import stockCloseService from "../../services/stock-close.service";
import { usePermissionContext } from "../../context/PremissionContext";

const OpStockCloseDetail = ({ close_data }) => {
    const { type } = useParams();
    const navigate = useNavigate();
    const { permissions } = usePermissionContext();
    const { setIsLoading, showToast, setRefereshData, refereshData } = useStateContext();

    const [data, setData] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [formValues, setFormValues] = useState([]);
    const [submittedData, setSubmittedData] = useState([]);

    useEffect(() => {
        getItemList();
    }, [refereshData, close_data]);

    useEffect(() => {
        setSubmittedData(formValues);
    }, [formValues]);

    const getItemList = () => {
        rawMaterialService.lists()
            .then(response => {
                const items = response.data.data;
                setItemList(items);
                const initialValues = items.map(item => ({
                    item_id: item.id,
                    unit: item.unit,
                    close_amount: '',
                    trans_to_shift: '',
                    transferable: item.transferable === "Yes"
                }));
                setFormValues(initialValues);
            })
            .catch(error => {
                showToast(error.response?.data?.message || "Failed to load items", "error");
            });
    };

    const handleInputChange = (index, field, value) => {
        const newFormValues = [...formValues];
        newFormValues[index] = { ...newFormValues[index], [field]: value };
        setFormValues(newFormValues);
    };

    const handleSubmitAll = () => {
        if (submittedData.length > 0) {
            setIsLoading(true);
            stockCloseService.storeAll({
                main: close_data,
                item: submittedData
            }, type)
                .then((response) => {
                    navigate("/app/operation/dashboard");
                    setIsLoading(false);
                    showToast("All Stock Close Details added successfully.", "success");
                    setSubmittedData([]);
                })
                .catch(error => {
                    navigate("/app/operation/dashboard");
                    setIsLoading(false);
                    showToast(error.response?.data?.message || "Failed to save details", "error");
                });
        } else {
            setIsLoading(false);
            showToast("No data to submit", "warning");
        }
    };

    return (
        <Fragment>
            <Col md="12">
                <h4>Stock Closing Detail</h4>
                <Form>
                    {itemList.map((item, index) => (
                        <Row key={item.id} className="text-start mb-3">
                            <Col md="4">
                                <Form.Label htmlFor={`item_${index}`} className="h6">
                                    Item Name
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    id={`item_${index}`}
                                    value={item.text}
                                    disabled
                                />
                            </Col>
                            <Col md="2">
                                <Form.Label htmlFor={`unit_${index}`} className="h6">
                                    Unit
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    id={`unit_${index}`}
                                    value={formValues[index]?.unit || ''}
                                    disabled
                                />
                            </Col>
                            <Col md="3">
                                <Form.Label htmlFor={`close_amount_${index}`} className="h6">
                                    Close Amount
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`close_amount_${index}`}
                                    value={formValues[index]?.close_amount || ''}
                                    onChange={(e) => handleInputChange(index, 'close_amount', e.target.value)}
                                    isInvalid={false}
                                />
                            </Col>
                            <Col md="3">
                                <Form.Label htmlFor={`trans_to_shift_${index}`} className="h6">
                                    Transferred Amount
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    id={`trans_to_shift_${index}`}
                                    value={formValues[index]?.trans_to_shift || ''}
                                    onChange={(e) => handleInputChange(index, 'trans_to_shift', e.target.value)}
                                    disabled={!formValues[index]?.transferable}
                                    isInvalid={false}
                                />
                            </Col>
                        </Row>
                    ))}
                </Form>
                <div className="mt-3 text-end">
                    <Button onClick={handleSubmitAll}>Submit All Data</Button>
                </div>
            </Col>
        </Fragment>
    );
};

export default OpStockCloseDetail;
